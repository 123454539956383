import quickFetch from 'quick-fetch';
import { doQuickLoad } from './quickLoad';
import { makeTypeMetadataEntryFetchKey, quickFetchTypeMetadataEntries } from './quickFetchFunctions/typeMetadata';
export const makeGroupsWithPropertiesQuickFetchKey = ({
  portalId = quickFetch.getPortalId(),
  frameworkTypeIdentifier,
  query
}) => `frameworkDataSchemaResolvers-${portalId}-properties-${frameworkTypeIdentifier}-${(query === null || query === void 0 ? void 0 : query.showHighlySensitiveProperties) || false}`;
export const getGroupsWithPropertiesFetchUrl = ({
  frameworkTypeIdentifier,
  query
}) => `/properties/v4/groups/${encodeURIComponent(frameworkTypeIdentifier)}/properties?includeFieldLevelPermission=true${query !== null && query !== void 0 && query.showHighlySensitiveProperties ? '&showHighlySensitiveProperties=true' : ''}`;
export const makeFrameworkTypesQuickFetchKey = ({
  portalId = quickFetch.getPortalId(),
  family
}) => `frameworkDataSchemaResolvers-${portalId}-frameworkTypes-${family}`;
const makeObjectTypesFetchUrl = () => '/customer-object-types/v1/for-portal';
const makeEventTypesFetchUrl = () => '/inbounddb-meta/v1/object-types/events/for-portal';
export const makeFrameworkTypesFetchUrl = ({
  family
}) => family === 'objects' ? makeObjectTypesFetchUrl() : makeEventTypesFetchUrl();
export const makePropertyMetadataEntriesFetchKey = ({
  frameworkTypeIdentifier,
  appSettingName,
  portalId = quickFetch.getPortalId()
}) => `frameworkDataSchemaResolvers-${portalId}-propertyMetadataEntries-${frameworkTypeIdentifier}-${appSettingName}`;
export const makePropertyMetadataEntriesFetchUrl = ({
  frameworkTypeIdentifier,
  appSettingName
}) => `/framework-builder/v1/read/metadata/property/${encodeURIComponent(frameworkTypeIdentifier)}/${encodeURIComponent(appSettingName)}`;
export const makePipelinesFetchKey = ({
  frameworkTypeIdentifier,
  portalId = quickFetch.getPortalId()
}) => `frameworkDataSchemaResolvers-${portalId}-pipelines-${frameworkTypeIdentifier}`;
export const makePipelinesFetchUrl = ({
  frameworkTypeIdentifier
}) => `/pipelines/v2/pipelines/${encodeURIComponent(frameworkTypeIdentifier)}?includeApprovalStageStatus=true&includePermissions=true`;

/**
 *
 * @param options.typeMetadataDebounceInterval Determines the debounce interval used to batch type metadata requests together. Each
 * instance has its own typeMetadata debounce and request queue.
 * @returns A promise that resolves to a FDSR quick-fetch client instance.
 */
export const buildQuickFetchClientInstance = ({
  typeMetadataDebounceInterval = 50
} = {}) => {
  const queuedTypeMetadataRequests = new Set();
  let typeMetadataRequestTimeout;
  const quickFetchGroupsWithPropertiesForType = ({
    frameworkTypeIdentifier,
    query
  }) => {
    const requestName = makeGroupsWithPropertiesQuickFetchKey({
      frameworkTypeIdentifier,
      query
    });
    doQuickLoad(requestName);
    if (quickFetch.getRequestStateByName(requestName)) {
      return;
    }
    quickFetch.makeEarlyRequest(requestName, {
      url: quickFetch.getApiUrl(getGroupsWithPropertiesFetchUrl({
        frameworkTypeIdentifier,
        query
      }), true),
      dataType: 'json',
      contentType: 'application/json',
      type: 'GET'
    });
  };
  const makeQuickFetchFrameworkTypesForFamily = ({
    family
  }) => () => {
    const requestName = makeFrameworkTypesQuickFetchKey({
      family
    });
    doQuickLoad(requestName);
    if (quickFetch.getRequestStateByName(requestName)) {
      return;
    }
    quickFetch.makeEarlyRequest(requestName, {
      url: quickFetch.getApiUrl(makeFrameworkTypesFetchUrl({
        family
      }), true),
      dataType: 'json',
      contentType: 'application/json',
      type: 'GET'
    });
  };
  const quickFetchPropertyMetadataEntries = ({
    frameworkTypeIdentifier,
    appSettingName
  }) => {
    const requestName = makePropertyMetadataEntriesFetchKey({
      frameworkTypeIdentifier,
      appSettingName
    });
    doQuickLoad(requestName);
    if (quickFetch.getRequestStateByName(requestName)) {
      return;
    }
    quickFetch.makeEarlyRequest(requestName, {
      url: quickFetch.getApiUrl(makePropertyMetadataEntriesFetchUrl({
        frameworkTypeIdentifier,
        appSettingName
      }), true),
      dataType: 'json',
      contentType: 'application/json',
      type: 'GET'
    });
  };
  const quickFetchPipelines = ({
    frameworkTypeIdentifier
  }) => {
    const requestName = makePipelinesFetchKey({
      frameworkTypeIdentifier
    });
    doQuickLoad(requestName);
    if (quickFetch.getRequestStateByName(requestName)) {
      return;
    }
    quickFetch.makeEarlyRequest(requestName, {
      url: quickFetch.getApiUrl(makePipelinesFetchUrl({
        frameworkTypeIdentifier
      }), true),
      dataType: 'json',
      contentType: 'application/json',
      type: 'GET'
    });
  };
  const client = {
    properties: {
      get: quickFetchGroupsWithPropertiesForType,
      getGroups: quickFetchGroupsWithPropertiesForType,
      // Preserves the `getProperty` interface just in case we ever
      // switch this to actually fetch individual properties (very unlikely)
      getProperty: ({
        frameworkTypeIdentifier,
        propertyName: __propertyName
      }) => quickFetchGroupsWithPropertiesForType({
        frameworkTypeIdentifier
      })
    },
    pipelines: {
      get: quickFetchPipelines,
      getPipeline: ({
        frameworkTypeIdentifier
      }) => quickFetchPipelines({
        frameworkTypeIdentifier
      })
    },
    frameworkTypes: {
      getEvents: makeQuickFetchFrameworkTypesForFamily({
        family: 'events'
      }),
      getObjects: makeQuickFetchFrameworkTypesForFamily({
        family: 'objects'
      })
    },
    typeMetadata: {
      get: ({
        appSettingNames
      }) => {
        // Type metadata is split out and cached by individual app setting name, so we need to
        // fire off loads for each app setting. doQuickLoad automatically ignores requests
        // that have already been fired, so we can just blindly fire them off without debouncing.
        appSettingNames.forEach(appSettingName => doQuickLoad(makeTypeMetadataEntryFetchKey({
          appSettingName
        })));
        if (typeMetadataRequestTimeout) {
          clearTimeout(typeMetadataRequestTimeout);
        }
        appSettingNames.forEach(name => queuedTypeMetadataRequests.add(name));

        // Straightforward debouncing approach. Collects all requests on a 50ms debounce,
        // then fires the request. This should generally catch the case where multiple QF clients
        // are constructed and used independently in a QF file
        typeMetadataRequestTimeout = setTimeout(() => {
          quickFetchTypeMetadataEntries({
            appSettingNames: [...queuedTypeMetadataRequests]
          });

          // Clear request queue
          queuedTypeMetadataRequests.clear();
        }, typeMetadataDebounceInterval);
      }
    },
    propertyMetadata: {
      get: quickFetchPropertyMetadataEntries
    },
    graph: {
      frameworkTypes: {
        getObjects: ({
          appSettingNames
        } = {}) => {
          client.frameworkTypes.getObjects();
          if (appSettingNames && appSettingNames.length) {
            client.typeMetadata.get({
              appSettingNames
            });
          }
        },
        getEvents: ({
          appSettingNames
        } = {}) => {
          client.frameworkTypes.getEvents();
          if (appSettingNames && appSettingNames.length) {
            client.typeMetadata.get({
              appSettingNames
            });
          }
        }
      },
      properties: {
        getGroups: ({
          frameworkTypeIdentifier,
          appSettingNames
        }) => {
          client.properties.getGroups({
            frameworkTypeIdentifier
          });
          appSettingNames.forEach(appSettingName => client.propertyMetadata.get({
            frameworkTypeIdentifier,
            appSettingName
          }));
        },
        getProperty: ({
          frameworkTypeIdentifier,
          propertyName: __propertyName,
          appSettingNames
        }) => {
          client.graph.properties.getGroups({
            frameworkTypeIdentifier,
            appSettingNames
          });
        },
        get: ({
          frameworkTypeIdentifier,
          appSettingNames
        }) => {
          client.graph.properties.getGroups({
            frameworkTypeIdentifier,
            appSettingNames
          });
        }
      }
    }
  };
  return Promise.resolve(client);
};