import { buildQuickFetchClientInstance } from './buildQuickFetchClientInstance';
export { makeGroupsWithPropertiesQuickFetchKey, getGroupsWithPropertiesFetchUrl, makeFrameworkTypesQuickFetchKey, makeFrameworkTypesFetchUrl, makePropertyMetadataEntriesFetchKey, makePropertyMetadataEntriesFetchUrl, makePipelinesFetchKey, makePipelinesFetchUrl } from './buildQuickFetchClientInstance';
export { makeTypeMetadataEntriesFetchKey, makeTypeMetadataEntryFetchKey, makeTypeMetadataEntriesFetchUrl, getTypeMetadataQuickFetches, takeTypeMetadataQuickFetch } from './quickFetchFunctions/typeMetadata';
export { withQuickLoad, enableEarlyCacheReturn, disableEarlyCacheReturn, isEarlyCacheReturnEnabled } from './quickLoad';
export { getFromIDB, setInIDB, clearIDB, __TEST_ONLY__DISABLE_IDB, __TEST_ONLY__REENABLE_IDB } from './persistence/indexeddb';
let instance = null;
export const getFrameworkDataQuickFetchClient = () => {
  if (instance === null) {
    instance = buildQuickFetchClientInstance().catch(err => {
      throw err;
    });
  }
  return instance;
};